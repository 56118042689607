<template>
  <div>
    <Myhead :nav="3"></Myhead>
    <div class="header">
      <div class="headerbox">
        <div class="header-router">
          <router-link class="routerLink2" to="/">{{ Language.qualityStack.home }}</router-link>
          >
          <router-link class="routerLink2" to="/our-services/quality-control-management">
            {{ Language.qualityStack.Ziniu_Quality_Management_Empowerment_System }}
          </router-link>
          >
          <span @click="reload" style="color: var(--theme-color1);cursor: pointer"
                v-if="znnav == 1">{{ Language.qualityStack.Ziniu_Quality_Stack }}</span>
          <span @click="reload" style="color: var(--theme-color1);cursor: pointer"
                v-if="znnav == 2">{{ Language.qualityStack.Ziniu_Quality_Chain }}</span>
        </div>
        <div class="header-title" v-if="znnav == 1">{{ Language.qualityStack.Ziniu_Quality_Stack }}</div>
        <div class="header-title" v-if="znnav == 2">{{ Language.qualityStack.Ziniu_Quality_Chain }}</div>
        <div class="header-text" style="margin-top: 18px;margin-bottom: 36px;" v-if="znnav == 1">
          {{ Language.qualityStack.msg1 }}
        </div>
        <div class="header-text" style="margin-top: 18px;margin-bottom: 36px;" v-if="znnav == 2">
          {{ Language.qualityStack.msg2 }}
        </div>

        <div class="btn-contactUs" @click="tocontactUs">{{ Language.qualityStack.Appointment_demonstration }}</div>
      </div>
    </div>
    <div class="item">
      <div :class="znnav == 1 ? 'item-checked' : ''" @click="changeznnav(1)">
        {{ Language.qualityStack.Ziniu_Quality_Stack }}
      </div>
      <div :class="znnav == 2 ? 'item-checked' : ''" @click="changeznnav(2)">
        {{ Language.qualityStack.Ziniu_Quality_Chain }}
      </div>
    </div>
    <div class="content1">
      <div class="content1-title" v-if="znnav == 1">{{ Language.qualityStack.Ziniu_Quality_Stack }}</div>
      <div class="content1-title" v-if="znnav == 2">{{ Language.qualityStack.Ziniu_Quality_Chain }}</div>
      <div class="content1-text" v-if="znnav == 1">
        {{ Language.qualityStack.msg1 }}
      </div>
      <div class="content1-text" v-if="znnav == 2">
        {{ Language.qualityStack.msg2 }}
      </div>
      <div class="content1-title" v-if="znnav == 1">{{ Language.qualityStack.Advantages_of_Ziniu_Quality_Stack }}</div>
      <div class="content1-title" v-if="znnav == 2">{{ Language.qualityStack.Advantages_of_Ziniu_Quality_Chain }}</div>
    </div>
    <div class="content2">
      <div class="content2-left">
        <div style="font-size: 42px;">01</div>
        <div style="font-size: 30px;" v-if="znnav == 1">{{ Language.qualityStack.msg3 }}</div>
        <div style="font-size: 30px;" v-if="znnav == 2">{{ Language.qualityStack.msg4 }}</div>
        <div class="content2-text" v-if="znnav == 1 ">
          {{ Language.qualityStack.msg5 }}
        </div>
        <div class="content2-text" v-if="znnav == 2 ">
          {{ Language.qualityStack.msg6 }}
        </div>
        <div class="btn-contactUs2" @click="tocontactUs">{{ Language.qualityStack.Contact_Us }}</div>
      </div>
      <div class="content2-img">
        <img class="imgStyle" v-if="znnav == 1 " src="../assets/img/qualityStack/banner11.jpg" alt/>
        <img class="imgStyle" v-if="znnav == 2 " src="../assets/img/qualityStack/banner1.jpg" alt/>
      </div>
    </div>
    <div class="content3">
      <div class="flex">
        <div class="content3-title">
          <div>02</div>
          <div style="font-size: 30px;" v-if="znnav == 1">{{ Language.qualityStack.msg7 }}</div>
          <div style="font-size: 30px;" v-if="znnav == 2">{{ Language.qualityStack.msg8 }}</div>
        </div>
        <div class="content3-text-box">
          <div class="content3-text" v-if="znnav == 1 ">
            {{ Language.qualityStack.msg9 }}
          </div>
          <div class="content3-text" v-if="znnav == 2 ">
            {{ Language.qualityStack.msg10 }}
          </div>
          <div class="btn-learnMore" @click="tocontactUs">{{ Language.qualityStack.Contact_Us }}</div>
        </div>
      </div>
      <div class="content3-img">
        <img class="imgStyle" v-if="znnav == 1 " src="../assets/img/qualityStack/banner22.jpg" alt/>
        <img class="imgStyle" v-if="znnav == 2 " src="../assets/img/qualityStack/banner2.jpg" alt/>
      </div>
    </div>
    <div class="content4">
      <div class="content4-img">
        <img class="imgStyle" v-if="znnav == 1 " src="../assets/img/qualityStack/banner33.jpg" alt/>
        <img class="imgStyle" v-if="znnav == 2 " src="../assets/img/qualityStack/banner3.jpg" alt/>
      </div>
      <div class="content4-right">
        <div style="float: right">
          <div style="font-size: 42px;">03</div>
          <div style="font-size: 30px;" v-if="znnav == 1">{{ Language.qualityStack.msg11 }}</div>
          <div style="font-size: 30px;" v-if="znnav == 1">{{ Language.qualityStack.msg12 }}</div>
          <div style="font-size: 30px;" v-if="znnav == 2">{{ Language.qualityStack.msg13 }}</div>
          <div style="font-size: 30px;" v-if="znnav == 2">{{ Language.qualityStack.msg14 }}</div>
          <div class="content4-text" v-if="znnav == 1">
            {{ Language.qualityStack.msg15 }}
          </div>
          <div class="content4-text" v-if="znnav == 2">
            {{ Language.qualityStack.msg16 }}
          </div>
          <div class="btn-contactUs2" @click="tocontactUs">{{ Language.qualityStack.Contact_Us }}</div>
        </div>
      </div>
    </div>
    <div class="content5">
      <div class="content5-container">
        <div>
          <div class="content5-title">{{ Language.qualityStack.Ziniu_Quality_Management_Empowerment_System }}</div>
          <div class="content5-text">{{ Language.qualityStack.msg17 }}</div>
        </div>
        <div class="btn-contactUs" @click="tocontactUs">{{ Language.qualityStack.Appointment_demonstration }}</div>
      </div>
    </div>
    <Myfoot></Myfoot>
  </div>
</template>
<script>
import Myhead from "@/component/myhead";
import Myfoot from "@/component/myfoot";

export default {
  inject: ['reload', 'L'],
  computed: {
    Language() {
      return this.L()
    }
  },
  components: {Myhead, Myfoot},
  data() {
    return {
      znnav: 1
    }
  },
  mounted() {
    if (this.$route.path == '/quality-control-management/quality-road') {
      this.znnav = 1
    }
    if (this.$route.path == '/quality-control-management/quality-chain') {
      this.znnav = 2
    }
    // this.znnav = this.$route.query.type
  },
  methods: {
    tocontactUs() {
      this.$router.push("/contact")
    },
    changeznnav(e) {
      this.znnav = e
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  margin-top: -70.5px;
  height: 480px;
  color: #ffffff;
  background-image: url("../assets/img/qualityStack/bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .headerbox {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 130px;

    .header-title {
      font-size: 50px;
    }
  }

  .header-router {
    margin-bottom: 36px;
    font-size: 14px;
  }

  .header-text {
    font-size: 18px;
    line-height: 28px;
  }
}

.item {
  height: 80px;
  padding: 0 20%;
  background-color: #FFFFFF;
  font-weight: bold;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-around;

  div {
    height: 80px;
    line-height: 80px;
    cursor: pointer;
  }

  .item-checked {
    color: var(--theme-color1);
    font-weight: bold;
    border-bottom: 2px solid var(--theme-color1);
  }
}

.content1 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 0 40px;

  .content1-title {
    color: var(--title-color);
    font-size: 42px;
    text-align: center;
  }

  .content1-text {
    color: var(--text-color);
    line-height: 28px;
    margin: 40px 0 120px;
  }
}

.content2 {
  display: flex;
  align-items: center;
  background-color: var(--theme-color1);
  color: #ffffff;
  height: 560px;
  position: relative;

  .content2-left {
    width: 1200px;
    margin: 0 auto;
    z-index: 2;

    .content2-text {
      max-width: 400px;
      line-height: 28px;
      margin: 20px 0;
    }
  }

  .content2-img {
    width: 960px;
    height: 560px;
    position: absolute;
    right: 0;
    z-index: 1;
  }
}

.content3 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 0;
  .content3-text-box{
    width: 585px;
  }
  .content3-title {
    max-width: 960px;
    font-size: 42px;
    color: var(--title-color);
  }

  .content3-text {
    color: var(--text-color);
  }

  .content3-img {
    margin-top: 40px;
    height: 400px;
  }
}

.content4 {
  display: flex;
  align-items: center;
  background-color: var(--theme-color1);
  color: #ffffff;
  height: 560px;
  position: relative;

  .content4-img {
    max-width: 960px;
    height: 560px;
    position: absolute;
    left: 0;
    z-index: 1;
  }

  .content4-right {
    width: 1200px;
    margin: 0 auto;
    z-index: 2;

    .content4-text {
      max-width: 400px;
      line-height: 28px;
      margin: 20px 0;
    }
  }
}

.content5 {
  height: 240px;
  background-image: url("../assets/img/qualityStack/banner4.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .content5-container {
    max-width: 1200px;
    height: 240px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;

    .content5-title {
      font-size: 42px;
      font-weight: bold;
      line-height: 58px;
      margin-bottom: 16px;
    }

    .content5-text {
      width: 745px;
      font-size: 24px;
      line-height: 38px;
    }
  }
}

@media (min-width: 0px) and (max-width: 1200px) {
  .header {
    .headerbox {
      padding: 0px 20px;
      padding-top: 130px;

      .header-title {
        font-size: 40px;
      }

      .header-text {
        font-size: 15px;
      }

      .btn-contactUs {
        width: 130px;
        line-height: 35px;
        height: 35px;
      }
    }

    .header-router {
      margin-bottom: 10px;
    }
  }

  .content1{
    .content1-text{
      padding: 0px 20px;
    }
  }

  .content2{
    .content2-left{
      padding: 20px 20px;
      background-color: rgba(0,0,0,0.5);
    }
    .content2-img{
      width: 100%;
      img{
        object-fit: cover;
      }
    }
  }

  .content3{
    .flex{
        padding: 0px 20px;
    }
    .content3-img{
      .imgStyle{
        object-fit: cover;
      }
    }
  }

  .content4{
    .content4-right{
      padding: 20px 20px;
      background-color: rgba(0,0,0,0.5);
    }
    .content4-img{
      img{
        object-fit: cover;
      }
    }
  }
  .content5{
    .content5-container{
      padding: 0px 20px;
      width: 100%;
      flex-wrap: wrap;
      .content5-title{
        font-size: 30px;
      }
      .content5-text{
        width: 100%;
        font-size: 18px;
        line-height: 20px;
      }
      .btn-contactUs{
        width: 130px;
        height: 35px;
        line-height: 35px;
      }
    }
  }
}
@media (min-width: 0px) and (max-width: 800px){
  .content3{
    .flex{
      flex-wrap: wrap;
    }
    .content3-title{
      width: 100%;
    }
    .content3-text-box{
      width: 100%;
      margin-top: 20px;
    }
  }
}
</style>
